import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { ICredentials } from 'src/app/account/domain/account.model';
import { ApiService } from 'src/app/common/infrastructure/api.service';
import { StorageService } from 'src/app/common/infrastructure/storage.service';

@Injectable({
  providedIn: 'root',
})
export class JwtService {

  private readonly _pathRefreshToken = '/auth/refresh';
  private readonly _pathLogin = '/auth/login';
  private readonly _pathLogout = '/auth/logout';
  private readonly _pathTokenLogin = '/auth/carrierDoc';

  private accessTokenSubject = new BehaviorSubject<string>('');
  private refreshTokenSubject = new BehaviorSubject<string>('');
  private usernameSubject = new BehaviorSubject<string>('');
  private userDataReceivedSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private storageService: StorageService,
    private apiService: ApiService
  ) { }

  public get accessToken(): string {
    return this.accessTokenSubject.getValue();
  }

  public get refreshToken(): string {
    return this.refreshTokenSubject.getValue();
  }

  public get username(): string {
    return this.usernameSubject.getValue();
  }

  public get userDataReceived$(): Observable<boolean> {
    return this.userDataReceivedSubject;
  }

  public setAccessToken(token: string) {
    this.accessTokenSubject.next(token);
    this.storageService.create('accessToken', token);
  }

  public setRefreshToken(token: string) {
    this.refreshTokenSubject.next(token);
    this.storageService.create('refreshToken', token);
  }

  public setUsername(username: string) {
    this.usernameSubject.next(username);
    this.storageService.create('username', username);
  }

  public setUserDataReceived(value: boolean) {
    this.userDataReceivedSubject.next(value);
  }


  public clearTokens() {
    this.setAccessToken('');
    this.setRefreshToken('');
    this.setUserDataReceived(false);
    this.storageService.delete('accessToken');
    this.storageService.delete('refreshToken');
  }

  public requestNewAuthToken$(): Observable<any> {
    const data = {
      refreshToken: this.refreshToken,
    };
    // TODO review this, refactor apiService methods params
    return this.apiService.post(
        this._pathRefreshToken,
        data,
        {},
        false)
      .pipe(
      // TODO review this, type
      tap( (response: any) => {
        this.setAccessToken(response.access_token);
        this.setRefreshToken(response.refresh_token)
      }),
      map((response: any) => {
        return response.access_token;
      })
    );
  }

  public login$(credentials: ICredentials): Observable<any> {
    this.setUsername(credentials.username);
    return this.apiService.post(
      this._pathLogin,
      credentials,
      {
        observe: 'body',
      },
      false,
    );
  }

  public validateToken$(token: string): Observable<any> {
    return this.apiService.post(
      this._pathTokenLogin,
      {
        uuid: token
      },
      {
        observe: 'body',
      },
      false,
    );
  }

  public logout$(): Observable<any> {
    return this.apiService.post(this._pathLogout, null);
  }
}
