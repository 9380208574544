import {
  HttpInterceptorFn,
  HttpContextToken,
  HttpErrorResponse,
  HttpRequest,
  HttpHandlerFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/auth/infrastructure/jwt.service';
import { ErrorService } from '../application/error.service';
import { AppInfoService } from './app-info.service';
import { environment } from 'src/environments/environment';

export const INCLUDE_AUTH_TOKEN = new HttpContextToken<boolean>(() => false);

const OVERRIDE_PRODUCTION_USERS = [
  {
    email: 'conductor@okcargo.io',
    phone: '666666670',
  },
];

const shouldOverrideLoginRequest = (req: HttpRequest<unknown>): boolean => {
  if (!req.url.includes('/auth/login')) return false;

  try {
    const body = req.body as { username?: string };
    if (!body?.username) return false;

    return OVERRIDE_PRODUCTION_USERS.some(
      (user) => user.email === body.username || user.phone === body.username
    );
  } catch {
    return false;
  }
};

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const jwtService = inject(JwtService);
  const router = inject(Router);
  const errorService = inject(ErrorService);
  const appInfoService = inject(AppInfoService);

  let revisedReq = req.clone();

  if (
    shouldOverrideLoginRequest(req) ||
    OVERRIDE_PRODUCTION_USERS.some(
      user =>
        user.email === jwtService.username ||
        user.phone === jwtService.username
    )
  ) {
    revisedReq = req.clone({
      url: req.url.replace(environment.apiUrl, environment.apiDemoUrl),
    });
  }

  if (req.context.get(INCLUDE_AUTH_TOKEN)) {
    const authReq = addAuthToken(revisedReq, jwtService);
    return next(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // TODO IMPORTANT we need a 401 error code
        if (error.status === 0) {
          return handle401Error(
            revisedReq,
            next,
            jwtService,
            router
          );
        } else {
          handleError(
            error,
            router,
            errorService,
            appInfoService,
          );
          return throwError(() => new Error(error.message));
        }
      })
    );
  }

  return next(revisedReq).pipe(
    catchError((error: HttpErrorResponse) => {
      handleError(
        error,
        router,
        errorService,
        appInfoService,
      );
      return throwError(() => new Error(error.message));
    })
  );
};

function addAuthToken(req: HttpRequest<unknown>, jwtService: JwtService) {
  const accessToken = jwtService.accessToken;
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function handle401Error(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  jwtService: JwtService,
  router: Router
) {
  return jwtService.requestNewAuthToken$().pipe(
    switchMap((newToken: string) => {
      jwtService.setAccessToken(newToken);
      const authReq = addAuthToken(req, jwtService);
      return next(authReq);
    }),
    catchError((error) => {
      jwtService.clearTokens();
      // TODO review this
      router.navigate(['/login']);
      return throwError(() => new Error(error.message));
    })
  );
}

// TODO implement this
function handleError(
  error: HttpErrorResponse,
  router: Router,
  errorService: ErrorService,
  appInfoService: AppInfoService,
) {
  console.error('Error', error);
  errorService.setError({
    message: error.message,
    status: error.status,
    detail: error.error.detail,
  });

  appInfoService.checkVersionOnError$().subscribe((isSupported) => {
    if (isSupported === false) {
      router.navigate(['/update-required']);
    } else {
      router.navigate(['/error']);
    }
  });
  // apiService.notifyUser(error.message || 'Error desconocido');
}
