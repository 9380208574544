import { Injectable } from '@angular/core';
import {
  AcceptFreightAssignationCommand,
  AcceptFreightAssignationCommandHandler,
  AddFreightAssignationCommand,
  AddFreightAssignationCommandHandler,
  CreateFreightCommand,
  CreateFreightCommandHandler,
  CreateFreightWithStopsAndAssignationsCommand,
  CreateFreightWithStopsAndAssignationsCommandHandler,
  RejectFreightAssignationCommand,
  RejectFreightAssignationCommandHandler,
  UpdateFreightCommand,
  UpdateFreightCommandHandler,
  SetFreightAssignationTypeCommand,
  SetFreightAssignationTypeCommandHandler,
  AcceptFreightAssignationFromMarketCommand,
  AcceptFreightAssignationFromMarketCommandHandler,
  ArriveFreightStopCommandHandler,
  StartFreightStopOperationCommandHandler,
  FinishFreightStopOperationCommandHandler,
  DepartFreightStopCommandHandler,
  ArriveFreightStopCommand,
  StartFreightStopOperationCommand,
  FinishFreightStopOperationCommand,
  DepartFreightStopCommand,
  VerifyFreightStopGoodsInfoCommandHandler,
  VerifyFreightStopGoodsInfoCommand,
  AddFreightStopAcceptanceInfoCommandHandler,
  AddFreightStopAcceptanceInfoCommand,
  UploadFreightDocumentCommandHandler,
  RemoveFreightDocumentCommandHandler,
  UploadFreightDocumentCommand,
  RemoveFreightDocumentCommand,
  ReplaceScheduledJourneyDriverCommand,
  ReplaceScheduledJourneyDriverCommandHandler,
  ReplaceScheduledJourneyVehicleCommand,
  ReplaceScheduledJourneyVehicleCommandHandler,
  CreateFreightMessageCommandHandler,
  CreateFreightMessageCommand,
  RequestPayInAdvanceCommandHandler,
  RequestPayInAdvanceCommand,
  SendDeliveryNoteInPaperCommand,
  SendDeliveryNoteInPaperCommandHandler,
  UploadCarrierDocumentCommandHandler,
  ReUploadCarrierDocumentCommand,
  ReUploadCarrierDocumentCommandHandler,
  UploadCarrierDocumentCommand,
  UpdateMailingPreferencesCommand,
  UpdateMailingPreferencesCommandHandler
} from '@okcargo/command-processor';
import { BaseCommandRepository } from 'src/app/common/infrastructure/command.repository';


@Injectable({ providedIn: 'root' })
export class FreightCommandService {
  private createFreightCommandHandler: CreateFreightCommandHandler;
  private updateFreightCommandHandler: UpdateFreightCommandHandler;
  private createFreightMessageCommandHandler: CreateFreightMessageCommandHandler;
  private createFreightWithStopsAndAssignationsCommandHandler: CreateFreightWithStopsAndAssignationsCommandHandler;
  private addFreightAssignationCommandHandler: AddFreightAssignationCommandHandler;
  private acceptFreightAssignationCommandHandler: AcceptFreightAssignationCommandHandler;
  private rejectFreightAssignationCommandHandler: RejectFreightAssignationCommandHandler;
  private setFreightAssignationTypeCommandHandler: SetFreightAssignationTypeCommandHandler;
  private acceptFreightAssignationFromMarketCommandHandler: AcceptFreightAssignationFromMarketCommandHandler;
  private arriveFreightStopCommandHandler: ArriveFreightStopCommandHandler;
  private startFreightStopOperationCommandHandler: StartFreightStopOperationCommandHandler;
  private finishFreightStopOperationCommandHandler: FinishFreightStopOperationCommandHandler;
  private departFreightStopCommandHandler: DepartFreightStopCommandHandler;
  private verifyFreightStopGoodsInfoCommandHandler: VerifyFreightStopGoodsInfoCommandHandler;
  private addFreightStopAcceptanceInfoCommandHandler: AddFreightStopAcceptanceInfoCommandHandler;
  private uploadFreightDocumentCommandHandler: UploadFreightDocumentCommandHandler;
  private removeFreightDocumentCommandHandler: RemoveFreightDocumentCommandHandler;
  private replaceScheduledJourneyDriverCommandHandler: ReplaceScheduledJourneyDriverCommandHandler;
  private replaceScheduledJourneyVehicleCommandHandler: ReplaceScheduledJourneyVehicleCommandHandler;
  private requestPayInAdvanceCommandHandler: RequestPayInAdvanceCommandHandler;
  private sendDeliveryNoteInPaperCommandHandler: SendDeliveryNoteInPaperCommandHandler;
  private uploadCarrierDocumentCommandHandler: UploadCarrierDocumentCommandHandler;
  private reuploadCarrierDocumentCommandHandler: ReUploadCarrierDocumentCommandHandler;

  // account
  private updateMailingPreferencesCommandHandler: UpdateMailingPreferencesCommandHandler;

  constructor(private commandRepository: BaseCommandRepository) {
    this.createFreightCommandHandler = new CreateFreightCommandHandler(commandRepository);
    this.updateFreightCommandHandler = new UpdateFreightCommandHandler(commandRepository);
    this.createFreightWithStopsAndAssignationsCommandHandler = new CreateFreightWithStopsAndAssignationsCommandHandler(commandRepository);
    this.addFreightAssignationCommandHandler = new AddFreightAssignationCommandHandler(commandRepository);
    this.acceptFreightAssignationCommandHandler = new AcceptFreightAssignationCommandHandler(commandRepository);
    this.rejectFreightAssignationCommandHandler = new RejectFreightAssignationCommandHandler(commandRepository);
    this.setFreightAssignationTypeCommandHandler = new SetFreightAssignationTypeCommandHandler(commandRepository);
    this.acceptFreightAssignationFromMarketCommandHandler = new AcceptFreightAssignationFromMarketCommandHandler(commandRepository);
    this.arriveFreightStopCommandHandler = new ArriveFreightStopCommandHandler(commandRepository);
    this.startFreightStopOperationCommandHandler = new StartFreightStopOperationCommandHandler(commandRepository);
    this.finishFreightStopOperationCommandHandler = new FinishFreightStopOperationCommandHandler(commandRepository);
    this.departFreightStopCommandHandler = new DepartFreightStopCommandHandler(commandRepository);
    this.verifyFreightStopGoodsInfoCommandHandler = new VerifyFreightStopGoodsInfoCommandHandler(commandRepository);
    this.addFreightStopAcceptanceInfoCommandHandler = new AddFreightStopAcceptanceInfoCommandHandler(commandRepository);
    this.uploadFreightDocumentCommandHandler = new UploadFreightDocumentCommandHandler(commandRepository);
    this.removeFreightDocumentCommandHandler = new RemoveFreightDocumentCommandHandler(commandRepository);
    this.replaceScheduledJourneyDriverCommandHandler = new ReplaceScheduledJourneyDriverCommandHandler(commandRepository);
    this.replaceScheduledJourneyVehicleCommandHandler =  new ReplaceScheduledJourneyVehicleCommandHandler(commandRepository);
    this.createFreightMessageCommandHandler = new CreateFreightMessageCommandHandler(commandRepository);
    this.requestPayInAdvanceCommandHandler = new RequestPayInAdvanceCommandHandler(commandRepository);
    this.sendDeliveryNoteInPaperCommandHandler = new SendDeliveryNoteInPaperCommandHandler(commandRepository);
    this.uploadCarrierDocumentCommandHandler = new UploadCarrierDocumentCommandHandler(commandRepository);
    this.reuploadCarrierDocumentCommandHandler = new ReUploadCarrierDocumentCommandHandler(commandRepository);

    // account
    this.updateMailingPreferencesCommandHandler = new UpdateMailingPreferencesCommandHandler(commandRepository);
  }

  createFreightWithStopsAndAssignations(command: CreateFreightWithStopsAndAssignationsCommand): Promise<void> {
    console.log('Creating freight with stops and assignations ', command.id);
    return this.createFreightWithStopsAndAssignationsCommandHandler.handle(command);
  }

  createFreight(command: CreateFreightCommand): Promise<void> {
    console.log('Creating freight ', command.id);
    return this.createFreightCommandHandler.handle(command);
  }

  updateFreight(command: UpdateFreightCommand) {
    console.log('Updating freight ', command.id);
    this.updateFreightCommandHandler.handle(command);
  }

  assignCarrierToFreight(command: AddFreightAssignationCommand) {
    console.log('Assigning carrier to freight ', command.assignationId);
    this.addFreightAssignationCommandHandler.handle(command);
  }

  acceptFreight(command: AcceptFreightAssignationCommand) {
    console.log('Accepting freight ', command.assignationId);
    this.acceptFreightAssignationCommandHandler.handle(command);
  }

  rejectFreight(command: RejectFreightAssignationCommand) {
    console.log('Rejecting freight ', command.assignationId);
    this.rejectFreightAssignationCommandHandler.handle(command);
  }

  setAssignationType(command: SetFreightAssignationTypeCommand) {
    this.setFreightAssignationTypeCommandHandler.handle(command);
  }

  acceptFreightAssignationFromMarket(command: AcceptFreightAssignationFromMarketCommand): Promise<void> {
    return this.acceptFreightAssignationFromMarketCommandHandler.handle(command);
  }

  arriveToFreightStop(command: ArriveFreightStopCommand): Promise<void> {
    return this.arriveFreightStopCommandHandler.handle(command);
  }

  startFreightStopOperation(command: StartFreightStopOperationCommand): Promise<void> {
    return this.startFreightStopOperationCommandHandler.handle(command);
  }

  finishFreightStopOperation(command: FinishFreightStopOperationCommand): Promise<void> {
    return this.finishFreightStopOperationCommandHandler.handle(command);
  }

  departFromFreightStop(command: DepartFreightStopCommand): Promise<void> {
    return this.departFreightStopCommandHandler.handle(command);
  }

  verifyFreightStopGoodsInfo(command: VerifyFreightStopGoodsInfoCommand): Promise<void> {
    return this.verifyFreightStopGoodsInfoCommandHandler.handle(command);
  }

  addFreightStopAcceptanceInfo(command: AddFreightStopAcceptanceInfoCommand): Promise<void> {
    return this.addFreightStopAcceptanceInfoCommandHandler.handle(command);
  }

  uploadFreightDocument(command: UploadFreightDocumentCommand): Promise<void> {
    return this.uploadFreightDocumentCommandHandler.handle(command);
  }

  removeFreightDocument(command: RemoveFreightDocumentCommand) {
    this.removeFreightDocumentCommandHandler.handle(command);
  }

  replaceScheduledJourneyDriver(command: ReplaceScheduledJourneyDriverCommand): Promise<void> {
    return this.replaceScheduledJourneyDriverCommandHandler.handle(command);
  }

  replaceScheduledJourneyVehicle(command: ReplaceScheduledJourneyVehicleCommand): Promise<void> {
    return this.replaceScheduledJourneyVehicleCommandHandler.handle(command);
  }

  sendMessage(command: CreateFreightMessageCommand): Promise<void> {
    console.log('Creating freight message ', command.id);
    return this.createFreightMessageCommandHandler.handle(command);
  }

  requestPayInAdvance(command: RequestPayInAdvanceCommand): Promise<void> {
    return this.requestPayInAdvanceCommandHandler.handle(command);
  }

  sendDeliveryNoteInPaper(command: SendDeliveryNoteInPaperCommand): Promise<void> {
    return this.sendDeliveryNoteInPaperCommandHandler.handle(command);
  }

  uploadCarrierDocument(command: UploadCarrierDocumentCommand): Promise<void>{
    return this.uploadCarrierDocumentCommandHandler.handle(command);
  }

  reuploadCarrierDocument(command: ReUploadCarrierDocumentCommand): Promise<void>{
    return this.reuploadCarrierDocumentCommandHandler.handle(command);
  }

  // account
  updateMailingPreferences(command: UpdateMailingPreferencesCommand): Promise<void>{
    return this.updateMailingPreferencesCommandHandler.handle(command);
  }
}
