import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Command, CommandRepository } from '@okcargo/command-processor';
import { ApiService } from './api.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BaseCommandRepository implements CommandRepository {
  private resourceUrl = '/services/command-processor/api/commands';

  constructor(
    private apiService: ApiService,
    protected http: HttpClient
  ) {}

  execute(_name: string, command: Command): Promise<any> {
    return firstValueFrom(
      this.apiService.post(
        this.resourceUrl,
        { name: _name, payload: command },
        { observe: 'body' }
      )
    );
  }
}
