import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { INCLUDE_AUTH_TOKEN } from './api.interceptor';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public readonly API_URL = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) {}

  private addContextToRequest(needToken: boolean, reqOpts: object) {
    if (needToken) {
      return {
        ...reqOpts,
        context: new HttpContext().set(INCLUDE_AUTH_TOKEN, true),
      }
    }
    return reqOpts;
  }


  // TODO refactor: arguments as a options object
  // use options (same structure as http methods) with 'superpowers' (new properties like needToken) or maybe customized object, i.e.:
/*

interface GetOptions {
  needToken?: boolean;
  params?: any;
  reqOpts?: any;
}

get( endpoint: string , options: IRequestContentOptions = {} )

*/

  get(endpoint: string, params?: any, reqOpts?: any, needToken: boolean = true) {
    if (params) {
      reqOpts = params;
    }
    reqOpts = this.addContextToRequest(needToken, reqOpts);
    return this.http.get(this.API_URL + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any, needToken: boolean = true) {
    reqOpts = this.addContextToRequest(needToken, reqOpts);
    return this.http.post(this.API_URL + endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any, needToken: boolean = true) {
    reqOpts = this.addContextToRequest(needToken, reqOpts);
    return this.http.put(this.API_URL + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any, needToken: boolean = true) {
    reqOpts = this.addContextToRequest(needToken, reqOpts);
    return this.http.delete(this.API_URL + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any, needToken: boolean = true) {
    reqOpts = this.addContextToRequest(needToken, reqOpts);
    return this.http.patch(this.API_URL + endpoint, body, reqOpts);
  }
}
