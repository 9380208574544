import { ApiService } from 'src/app/common/infrastructure/api.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable, tap } from 'rxjs';
import {
  ECapabilities,
  getEmptyIAccount,
  IAccount,
  ICredentials,
} from '../domain/account.model';
import { TranslateService } from '@ngx-translate/core';
import { enGB, es } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { JwtService } from 'src/app/auth/infrastructure/jwt.service';
import { FreightCommandService } from 'src/app/freight/infrastructure/freight-command.service';
import { UpdateMailingPreferencesCommand } from '@okcargo/command-processor';
import { transformToIAccount } from '../infrastructure/account-mapper';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private readonly _endpointAccount = '/services/uaa/api/account';
  private readonly _endpointChangeLaguage = '/services/uaa/api/account/change-language';
  private readonly _endpointCompany = '/services/uaa/api/carriers/mine';
  private readonly _endpointCompanyCapabilities = '/services/uaa/api/company-capabilities/company';
  private readonly _endpointChangePassword = '/services/uaa/api/account/change-password';
  private readonly _endpointCompanyCarriers = '/services/uaa/api/company-carriers';

  private _account$: BehaviorSubject<IAccount> = new BehaviorSubject(
    getEmptyIAccount()
  );

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private freightCommandService: FreightCommandService,
    private translate: TranslateService,
    private dateFnsConfigurationService: DateFnsConfigurationService
  ) {}

  get account$(): Observable<IAccount> {
    return this._account$.asObservable();
  }

  public async checkUserAuthentication() {
    try {
      const user = await this.getIdentity();
      this.setDateFnsLocale(user.lang);
      this.jwtService.setUserDataReceived(true);
      this._account$.next(user);
    } catch (error) {
      console.warn(error);
    }
  }

  public hasCapability$(capability: ECapabilities): Observable<boolean> {
    return this._account$.pipe(
      map((account) => account.capabilities.includes(capability))
    );
  }

  public get userCapabilities$(): Observable<ECapabilities[]> {
    return this._account$.pipe(map((account) => account.capabilities || []));
  }

  public async login(credentials: ICredentials): Promise<void> {
    console.log('login() in account service');
    await firstValueFrom(
      this.jwtService.login$(credentials).pipe(
        tap((response) => {
          this.jwtService.setAccessToken(response.access_token);
          this.jwtService.setRefreshToken(response.refresh_token);
        })
      )
    );
    await this.checkUserAuthentication();
  }

  public async validateToken(token: string): Promise<void> {
    console.log('validateToken() in account service');
    await firstValueFrom(
      this.jwtService.validateToken$(token).pipe(
        tap((response) => {
          this.jwtService.setAccessToken(response.access_token);
          this.jwtService.setRefreshToken(response.refresh_token);
        })
      )
    );
    await this.checkUserAuthentication();
  }

  public async logout() {
    await firstValueFrom(this.jwtService.logout$());
    this._account$.next(getEmptyIAccount());
    this.jwtService.clearTokens();
  }

  public saveLang(lang: string) {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.setDateFnsLocale(lang);
    return firstValueFrom(
      this.apiService.post(
        this._endpointChangeLaguage,
        {
          langKey: lang,
        },
        { observe: 'response' }
      )
    );
  }

  public async changePassword(currentPassword: string, newPassword: string) {
    return await firstValueFrom(
      this.apiService.post(
        this._endpointChangePassword,
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        { observe: 'response' }
      )
    );
  }

  public async updateMailingPreferences(
    command: UpdateMailingPreferencesCommand
  ) {
    try {
      await this.freightCommandService.updateMailingPreferences(
        command as UpdateMailingPreferencesCommand
      );
    } catch (error: any) {
      // TODO implement this
      console.warn(error);
    }
  }

  private async getIdentity(): Promise<IAccount> {
    const account = this._account$.getValue();
    const accountResponse = await this.findAccount();
    const companyResponse = await this.findCompany();
    const companyCapabilitiesResponse = await this.findCompanyCapabilities();
    const companyAsCarrier = await this.findCompanyAsCarrier();

    return transformToIAccount(
      accountResponse,
      companyResponse,
      companyCapabilitiesResponse,
      companyAsCarrier,
      account
    );
  }

  private async findCompanyCapabilities(): Promise<any> {
    return await firstValueFrom(
      this.apiService.get(this._endpointCompanyCapabilities, {
        observe: 'body',
      })
    );
  }

  private async findCompany(): Promise<any> {
    return await firstValueFrom(
      this.apiService.get(this._endpointCompany, { observe: 'body' })
    );
  }

  private async findAccount(): Promise<any> {
    return await firstValueFrom(
      this.apiService.get(this._endpointAccount, { observe: 'body' })
    );
  }

  private async findCompanyAsCarrier(): Promise<any> {
    return await firstValueFrom(
      this.apiService.get(this._endpointCompanyCarriers, { observe: 'body' })
    );
    // .get(this._endpointCompanyCarriers, { observe: 'body' })
    // .pipe(
    //   map((response) => {
    //     console.log('getCostCenters() response', response)
    //     if (Array.isArray(response) && response.length > 0) {
    //       const costCenters = response[0]?.company?.costCenters || [];
    //       return costCenters.map((center: ICostCenter) => ({
    //         id: center.id,
    //         name: center.name
    //       }));
    //     } else return [];
    //   })
    // )
    // .subscribe({
    //   next: (costCenters: ICostCenter[]) => {
    //     return this._costCentersSubject.next(costCenters);
    //   },
    //   error: (err) => console.error('Error loading cost centers:', err),
    // });
  }

  private setDateFnsLocale(lang: string) {
    if (lang === 'es') {
      this.dateFnsConfigurationService.setLocale(es);
    } else {
      this.dateFnsConfigurationService.setLocale(enGB);
    }
  }

  // TODO ¿?¿?¿?
  /*
  acceptPrivacyPolicy() {
    return this.http.put(
      ApiService.API_URL + '/services/uaa/api/account/privacy-policy',
      {},
      { observe: 'response' }
    );
  }

  */
}
