export interface IAccount {
  capabilities: ECapabilities[], // NEW
  role: ERoles, // NEW
  lang: string,

  id: number, // really unnecessary, identified in backend by token
  firstName: string, // from /services/uaa/api/drivers/me-light (user object)
  lastName: string,
  email: string,
  username: string,
  privacyPolicyAccepted?: boolean,
  // activated?: boolean,
  company: ICompany, // from /services/uaa/api/carriers/mine (company object)

  emailPeriodicity: EMailingPeriodicity;

}
export interface ICompany {
  id?: number;
  name: string;
  taxNumber: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  stateProvince: string;
  country: string;
  phone: string;
  activated: boolean;
  isInternational: boolean;
  // isFreelance: boolean; // from root json (outside company object); not in use
  isMarketAllowed: boolean;
}

export function getEmptyIAccount(): IAccount {
  return {
    id: 0,

    capabilities: [] as const, // NEW
    role: ERoles.DEFAULT, // NEW
    lang: "es",

    firstName: "",
    lastName: "",
    email: "",
    username: "",
    company: {
      // id: undefined,
      name: "",
      taxNumber: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      stateProvince: "",
      country: "",
      phone: "",
      activated: false,
      isInternational: false,
      // isFreelance: false,
      isMarketAllowed: true,
    },

    emailPeriodicity: EMailingPeriodicity.NEVER,
  }
}

export interface ICredentials {
  username: string,
  password: string,
  rememberMe: boolean
}

export enum ERoles {
  DEFAULT = "DEFAULT",
  DRIVER = "DRIVER",
  ADMINISTRATIVE = "ADMINISTRATIVE",
  DRIVER_ADMINISTRATIVE = "DRIVER_ADMINISTRATIVE"
}

export enum ECapabilities {
  AUTHENTICATED = "AUTHENTICATED",
  INTERACT_WITH_STOPS = "INTERACT_WITH_STOPS",
  ADMINISTRATIVE_DOCUMENTS = "ADMINISTRATIVE_DOCUMENTS",
  VIEW_PRICES = "VIEW_PRICES",
  VIEW_MARKET = "VIEW_MARKET",
  VIEW_DRIVERS = "VIEW_DRIVERS",
  UPLOAD_FREIGHTS = "UPLOAD_FREIGHTS",
  CRUD_VEHICLES = "CRUD_VEHICLES", //
  CRUD_USERS = "CRUD_USERS", //
}

export enum EMailingPeriodicity {
  REALTIME = "REALTIME",
  HALF_HOURLY =  "HALF_HOURLY",
  HOURLY = "HOURLY",
  TWICE_A_DAY = "TWICE_A_DAY",
  MID_WORKING_HOUR = "MID_WORKING_HOUR",
  LAST_WORKING_HOUR = "LAST_WORKING_HOUR",
  NEVER = "NEVER",
}
